
import Node from '../components/grid-node.vue';
import firstNode from '../components/firstNode.vue';

export default class HelperGenerater {

  constructor(data) {
    this.data = data
    this.endpoints = [
      {
        id: '1',
        orientation: [0, -1],
        pos: [0.5, 0]
      }, 
      {
        id: '2',
        orientation: [0, 1],
        pos: [0.5, 0]
      }
    ]
    this.count = 0
    this.edges = []
  }

  getData() {
    let edges = this.getEdges()
    let children = this.getChilds()

    let data = {
      nodes : {
        isRoot: true,
        id: 'Root',
        title:'СТРУКТУРА ОРГАНИЗАЦИИ',
        render: firstNode,
        endpoints: [{
          id: '1',
          orientation: [0, 1],
          pos: [0.5, 0]
        }],
        children: children
      },
      edges: edges
    }
    return data
  }

  getChilds() {
    let array = []
    for (let item of this.data) {
      let obj = {
        id: item.id + item.key,
        render: Node,
        title: item.name,
        key: item.key,
        img: 'https://bmt.gohr.uz/img/icon/company.svg',
        min: item.self_staff_count,
        max: item.staff_count,
        background: '#0096FF',
        open: item.id,
        content: 'sub node 1',
        endpoints: this.endpoints,
        children: this.loopChild(item)
      }


      array.push(obj)
    }
    
    return array;
  }

  loopChild(data) {
    let array = []

    if (data.children.length > 0) {
      for (let item of data.children) {
        let obj = {
          id: item.id + item.key,
          render: Node,
          title: item.name,
          key: item.key,
          img: 'https://bmt.gohr.uz/img/icon/company.svg',
          min: item.self_staff_count,
          max: item.staff_count,
          open: item.id,
          background: '#0096FF',
          content: 'sub node 1',
          endpoints: this.endpoints,
          children: this.loopChild(item)
        }

        array.push(obj)
      }
    }
    
    
    return array;
  }

  getEdges() {
    
    for (let item of this.data) {
      let obj = {
        id: this.count, // i this.count
        source: '1',
        target: '1',
        sourceNode: 'Root',
        targetNode: item.id + item.key, //key id
        type: 'endpoint'
      }
      this.edges.push(obj)

      if ( item.children.length > 0 ) {
        this.loopEdges(item)
      } else {
        this.count ++
      }
    }

    return this.edges
  }

  loopEdges(data) {

    for (let item of data.children) {
      let obj = {
        id: this.count, // i this.count
        source: '2',
        target: '1',
        sourceNode: data.id + data.key, // data.key + data.id
        targetNode: item.id + item.key, //item.key + item.id
        type: 'endpoint'
      }
      this.edges.push(obj)

      if ( item.children.length > 0 ) {
        this.loopEdges(item)
      } else {
        this.count ++
      }
    }
  }

}
