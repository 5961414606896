import { render, staticRenderFns } from "./vue-endpoint.vue?vue&type=template&id=32d23ac1"
import script from "./vue-endpoint.vue?vue&type=script&lang=js"
export * from "./vue-endpoint.vue?vue&type=script&lang=js"
import style0 from "./vue-endpoint.vue?vue&type=style&index=0&id=32d23ac1&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports