<template>
  <!-- <div class="grid-node">
    {{itemData.label}}
  </div> -->
  <div class="st__block1" >
    <div class="st__header">
      <div class="st__title">
        <div class="st__text" >
          <h5>{{ itemData.title }}</h5>
        
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "grid-node",

  props: {
    // 这里可以拿到mockdata里的当前节点的数据
    itemData: {
      type: Object,
    },
    // 原生的节点数据（不推荐使用这个）
    canvasNode: {
      type: Object
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
    })
  },
  created() {

  }
};
</script>

<style scoped>
  .grid-node {
    width: 200px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #aaa;
    color: #FFF;
    background-color: #F66902;
  }

</style>
